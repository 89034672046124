import React from 'react';
import { FormattedMessage } from '../../util/reactIntl';

import css from './ListingPage.module.css';

const SectionPricingMaybe = props => {
  const { formattedPrice, formattedPriceDiscount1, formattedPriceDiscount2 } = props;
  return formattedPrice || formattedPriceDiscount1 || formattedPriceDiscount2 ? (
    <div className={css.sectionPricing}>
      <h2 className={css.pricingTitle}>
        <FormattedMessage id="ListingPage.pricingTitle" />
      </h2>
      <ul>
        {formattedPrice ? (
          <li>
            {formattedPrice} <FormattedMessage id="BookingPanel.perUnit" />
          </li>
        ) : null}
        {formattedPriceDiscount1 ? (
          <li>
            {formattedPriceDiscount1} <FormattedMessage id="BookingPanel.perUnitDiscount1" />
          </li>
        ) : null}
        {formattedPriceDiscount2 ? (
          <li>
            {formattedPriceDiscount2} <FormattedMessage id="BookingPanel.perUnitDiscount2" />
          </li>
        ) : null}
      </ul>
    </div>
  ) : null;
};

export default SectionPricingMaybe;
